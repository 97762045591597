@import "../variables.modules";

nav {
  transition: $transition;
}

.active {
  border-bottom: 5px solid;
  border-image-source: linear-gradient(to right, $purple, $pink);
  border-image-slice: 1;

  @media only screen and (max-width: 940px) {
    border-bottom: 5px solid $purple;
    border-image-source: unset;
    border-image-slice: unset;
  }
}

li {
  transition: $transition;

  &:hover {
    transform: translateY(-3px);
    transition: $transition;
  }
}